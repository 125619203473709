import { isAxiosError } from "axios";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { PostApi } from "shared/libs/api";
import { API } from "shared/libs/conf-axios";
import { Post } from "shared/types";
import PostListItem from "../postListItem";

const PostList = ({
  id,
  setId,
}: {
  id: string;
  setId: Dispatch<SetStateAction<string>>;
}) => {
  const [posts, setPosts] = useState<Post[]>([]);
  console.log(posts);
  const [notificationError, setNotificationError] = useState("");
  const getPostData = async () => {
    try {
      const res = await API.get(PostApi.GET_POSTS);
      if (isAxiosError(res)) {
        //@ts-ignore
        setNotificationError(res.response?.data?.message);
        return;
      } else {
        setPosts(res.data.notifications);
      }
      setNotificationError("");
    } catch (error) {
      console.log(error, "false");
    }
  };

  useEffect(() => {
    getPostData();
  }, []);
  return (
    <div>
      <h1 className="text-[30px] mb-3">Лист постов:</h1>
      {notificationError ? (
        notificationError
      ) : (
        <div className="flex flex-col w-full justify-start gap-[30px]">
          {posts.length
            ? posts.map((post: Post) => (
                <PostListItem
                  id={id}
                  create_time={post.create_time}
                  setId={setId}
                  approvals={post.approvals}
                  title={post.title}
                  body={post.body}
                  notification_id={post.notification_id}
                  publication_time={post.publication_time}
                  language_name={post.language_name}
                  category_name={post.category_name}
                  key={post.notification_id}
                />
              ))
            : ""}
        </div>
      )}
    </div>
  );
};
export default PostList;
