import { isAxiosError } from "axios";
import PostForm from "comopnents/form";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { PostApi } from "shared/libs/api";
import { API } from "shared/libs/conf-axios";
import { CreatePostType, Post } from "shared/types";

function convertToTimestamp(dateString: string) {
  const date = new Date(dateString);
  const timestamp = Math.floor(date.getTime() / 1000);
  return timestamp;
}

function ChangePost({
  id,
  setId,
}: {
  id: string;
  setId: Dispatch<SetStateAction<string>>;
}) {
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [errorApprove, setErrorApprove] = useState("");
  const [post, setPost] = useState<Post>();

  useEffect(() => {
    const timeOut = setTimeout(() => setErrorApprove(""), 3000);

    return () => {
      if (timeOut) {
        clearTimeout(timeOut);
      }
    };
  }, [errorApprove]);
  const getPost = async () => {
    try {
      if (!id) return;
      const params = {
        notification_id: id,
      };
      const res = await API.get(PostApi.GET_POST, { params });
      setPost(res.data);
      if (isAxiosError(res)) {
        return;
      }
    } catch (error) {
      console.log(error);
    }
  };
  const approvePost = async () => {
    try {
      setErrorApprove("");
      if (!id) return;
      const params = {
        notification_id: id,
      };
      const res = await API.post(PostApi.PUT_APPROVE, params);
      if (isAxiosError(res)) {
        setErrorApprove(res.response?.data.message);
        return;
      }
      getPost();
    } catch (error) {
      console.log(error);
      if (isAxiosError(error)) {
        setErrorApprove(error.response?.data.message);
      }
    }
  };

  const disApprovePost = async () => {
    try {
      if (!id) return;
      const params = {
        notification_id: id /* add other query parameters here */,
      };
      const res = await API.post(PostApi.PUT_DISAPPROVE, params);
      if (isAxiosError(res)) {
        setErrorApprove(res.response?.data.message);
        return;
      }
      getPost();
    } catch (error) {
      console.log(error);
    }
  };

  const deletePost = async () => {
    try {
      if (!id) return;
      const params = {
        notification_id: id /* add other query parameters here */,
      };
      await API.delete(PostApi.DELETE_POST, { data: params });
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getPost();
  }, [id]);
  const onFinish = (
    values: CreatePostType,
    languagesOption?: { value: string; label: string }[],
    categoryOptions?: { value: string; label: string }[],
  ) => {
    values.notification_id = id || "";
    const codeLang =
      languagesOption &&
      languagesOption.filter(
        (lang) =>
          lang.label === values.language_id ||
          lang.value === values.language_id,
      );
    const codeCategory =
      categoryOptions &&
      categoryOptions.filter(
        (lang) =>
          lang.label === values.category_id ||
          lang.value === values.category_id,
      );
    if (codeLang && codeCategory) {
      values.language_id = codeLang && codeLang[0]?.value;
      values.category_id = codeLang && codeCategory[0]?.value;
    }
    values.publication_time = convertToTimestamp(
      values.publication_time.toString(),
    );

    const changePost = async () => {
      setIsSubmitLoading(true);
      try {
        await API.put(PostApi.PUT_POST, values);

        window.location.reload();
      } catch (error) {
        console.log(error, "false");
      }
    };
    changePost();
  };
  return (
    <>
      <PostForm
        title="Редактирование поста"
        approvePost={approvePost}
        disApprovePost={disApprovePost}
        deletePost={deletePost}
        submitTitle="Изменить"
        onFinish={onFinish}
        isSubmitLoading={isSubmitLoading}
        defaultValue={post}
      />
      <div
        onClick={() => setId("")}
        className={`fixed right-[390px] cursor-pointer
        rotate-45 text-[30px] top-[160px] z-20`}
      >
        +
      </div>
      {errorApprove && (
        <div className="fixed right-[10px] bg-white border-[1px] rounded-[30px] border-error w-fit bottom-[10px] p-3 h-[40px] flex items-center justify-center ">
          {errorApprove}
        </div>
      )}
    </>
  );
}

export default ChangePost;
