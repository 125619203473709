import { Card } from "antd";
import { convertTimeStamp } from "shared/libs/utils";
import { Post } from "shared/types";

const PostListItem = ({
  title,
  id,
  body,
  publication_time,
  create_time,
  language_name,
  category_name,
  notification_id,
  setId,
}: Post) => {
  const setPost = () => {
    if (setId) setId(notification_id);
  };
  return (
    <Card
      style={{
        backgroundColor: `${id === notification_id ? " rgb(211 220 230)" : "white"}`,
        border: 0,
      }}
      onClick={setPost}
      className={`hover:shadow-xl cursor-pointer w-full transition-all `}
      title={title}
      bordered={false}
    >
      <p>Категория: {category_name}</p>
      <p>Язык: {language_name}</p>
      <p className="truncate">Текст: {body}</p>

      <p>Создан: {convertTimeStamp(create_time || "")}</p>
      <p>Публикация: {convertTimeStamp(publication_time || "")}</p>
    </Card>
  );
};
export default PostListItem;
