import { useEffect, useState } from "react";
import { Alert, Button, DatePicker, Form, Input, Select, Space } from "antd";
import { useForm } from "antd/es/form/Form";
import TextArea from "antd/es/input/TextArea";
import { PostApi } from "shared/libs/api";
import { API } from "shared/libs/conf-axios";
import { isSuccessStatusCode } from "shared/libs/utils";
import { CreatePostType, ICatigories, LanguagesType, Post } from "shared/types";
import Loader from "shared/ui/loader";
import dayjs from "dayjs";

const PostForm = ({
  onFinish,
  isSubmitLoading,
  defaultValue,
  title,
  approvePost,
  disApprovePost,
  deletePost,
  submitTitle,
}: {
  onFinish: (
    values: CreatePostType,
    languagesOption?: { value: string; label: string }[],
    categoryOptions?: { value: string; label: string }[],
  ) => void;
  submitTitle: string;
  approvePost?: () => void;
  disApprovePost?: () => void;
  deletePost?: () => void;
  title: string;
  defaultValue?: Post;
  isSubmitLoading: boolean;
}) => {
  const [categories, setCategories] = useState([]);
  const [categoriesError, setCategoriesError] = useState("");
  const [languages, setLanguages] = useState([]);
  const [languagesError, setLanguagesError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isWontDelete, setIsWontDelete] = useState(false);
  const [isPublic, setIsPublic] = useState(false);
  const [form] = useForm();
  useEffect(() => {
    if (defaultValue)
      if (defaultValue?.approvals.length === 3) {
        setIsPublic(true);
      } else {
        setIsPublic(false);
      }
  }, [defaultValue]);
  useEffect(() => {
    if (defaultValue) {
      form.setFieldsValue({
        title: defaultValue.title,
        body: defaultValue.body,
        category_id: defaultValue.category_name,
        language_id: defaultValue.language_name,
        publication_time: dayjs.unix(+defaultValue.publication_time),
      });
    }
  }, [defaultValue]);
  const getCategories = async () => {
    setIsLoading(true);
    try {
      setCategoriesError("");
      const res = await API.get(PostApi.GET_CATEGORIES);
      if (isSuccessStatusCode(res.status)) {
        setCategories(res.data.categories);
      }
    } catch (error) {
      setCategoriesError("Ошибка получения категорий");
    }
  };

  const getLanguages = async () => {
    try {
      setLanguagesError("");
      const res = await API.get(PostApi.GET_lANGUAGES);
      if (isSuccessStatusCode(res.status)) {
        setLanguages(res.data.languages);
      }
    } catch (error) {
      setLanguagesError("Ошибка получения языков");
    }
    setIsLoading(false);
  };
  useEffect(() => {
    getCategories();
    getLanguages();
  }, []);
  const categoryOptions = categories.map((category: ICatigories) => ({
    value: category.category_id,
    label: category.translated_name,
  }));
  const languagesOption = languages.map((language: LanguagesType) => ({
    value: language.language_id,
    label: language.name,
  }));
  const onReset = () => {
    form.resetFields();
  };

  return (
    <div className="flex flex-col items-center gap-[30px] p-[1rem] fixed right-0 top-[10%] w-[30%] z-10">
      {isLoading || isSubmitLoading ? (
        <Loader />
      ) : (
        <>
          <h1 className="text-[30px]">{title}</h1>

          <Form
            form={form}
            name="control-hooks"
            className="flex flex-col justify-center w-full p-[24px] rounded-[8px] relative bg-[#d3dce6]"
            onFinish={(values) =>
              onFinish(values, languagesOption, categoryOptions)
            }
            style={{ maxWidth: 500 }}
          >
            <Form.Item name="category_id" rules={[{ required: true }]}>
              <Select
                disabled={isPublic}
                className="w-full"
                placeholder="Категории"
                options={categoryOptions}
              />
            </Form.Item>
            <Form.Item name="title" rules={[{ required: true }]} help="">
              <Input placeholder="Заголовок" disabled={isPublic} />
            </Form.Item>
            <span className="text-gray-dark text-[10px] opacity-60 absolute w-full left-[24px] top-[113px]">
              Тридцатый символ обрезаеться многоточием.
            </span>
            <Form.Item name="body" rules={[{ required: true }]} help="">
              <TextArea placeholder="Текст" disabled={isPublic} />
            </Form.Item>
            <Form.Item
              name="publication_time"
              rules={[{ required: true }]}
              help=""
            >
              <DatePicker
                showTime
                className="min-w-[200px]"
                disabled={isPublic}
                placeholder={"Выбрать дату"}
              />
            </Form.Item>
            <Form.Item name="language_id" rules={[{ required: true }]} help="">
              <Select
                placeholder="Язык"
                disabled={isPublic}
                className="w-full"
                options={languagesOption}
              />
            </Form.Item>
            <Form.Item>
              <Space>
                <Button htmlType="submit" type="default" disabled={isPublic}>
                  {submitTitle}
                </Button>
                <Button
                  type="link"
                  htmlType="button"
                  disabled={isPublic}
                  onClick={onReset}
                >
                  Отчистить форму
                </Button>
              </Space>
            </Form.Item>
            <div className="flex items-center justify-between w-full">
              {defaultValue && (
                <div className="flex gap-4">
                  <Button
                    onClick={() => setIsWontDelete(true)}
                    htmlType="button"
                    type="primary"
                    danger
                  >
                    Удалить
                  </Button>
                  <Button
                    disabled={isPublic}
                    htmlType="button"
                    type="primary"
                    onClick={() => disApprovePost && disApprovePost()}
                    danger
                  >
                    -
                  </Button>
                  <Button
                    disabled={isPublic}
                    type="default"
                    onClick={() => approvePost && approvePost()}
                  >
                    +
                  </Button>
                </div>
              )}
              {defaultValue && (
                <div>
                  <div className="flex gap-4">
                    <span className="font-bold">Одобрено:</span>
                    <p>{defaultValue?.approvals?.length}</p>
                  </div>
                  <div className="flex flex-col gap-1">
                    {defaultValue?.approvals?.map((approv) => (
                      <span key={approv.username}>{approv.username}</span>
                    ))}
                  </div>
                </div>
              )}
            </div>
            {isWontDelete && (
              <Alert
                message="Удалить пост?"
                className="mt-2"
                description={defaultValue?.title}
                type="info"
                action={
                  <Space
                    direction="vertical"
                    className="flex flex-col items-center"
                  >
                    <Button
                      size="small"
                      onClick={() => deletePost && deletePost()}
                      danger
                      ghost
                    >
                      Удалить
                    </Button>
                    <Button
                      size="small"
                      onClick={() => setIsWontDelete(false)}
                      type="default"
                    >
                      Отменить
                    </Button>
                  </Space>
                }
                closable={false}
              />
            )}
          </Form>
        </>
      )}
      {languagesError && <span>{languagesError}</span>}
      {languagesError && <span>{categoriesError}</span>}
    </div>
  );
};

export default PostForm;
