export const isSuccessStatusCode = (status: number): boolean => {
  if (status >= 200 && status < 300) return true;
  return false;
};
export const convertTimeStamp = (time: string) => {
  const date = new Date(+time * 1000); // convert timestamp to milliseconds

  return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
};
export const errorStatusHandler = (status: number): string => {
  if (status === 500) return "Ошибка сервера";
  else if (status === 404) return "Not Found";
  else if (status === 406) return "Wrong Credentials";
  else return "Ошибка сервера";
};

export const getDateTime = (date: number | string) => {
  const options: any = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  };
  if (typeof date === "number") {
    return new Intl.DateTimeFormat("ru", options).format(date * 1000);
  } else if (typeof date === "string") {
    return new Intl.DateTimeFormat("ru", options).format(new Date(date));
  } else {
    return ``;
  }
};

export const getNetworkLink = (token: string, hash: string) => {
  if (token === "ETH" || token === "USDT")
    return `https://etherscan.io/tx/${hash}`;
  else if (token === "TRX" || token === "TRC20")
    return `https://tronscan.org/#/transaction/${hash}`;
  else return "";
};

export function millisToSeconds(millis: number) {
  const seconds = millis / 1000;
  return seconds;
}
export const monthNames = [
  "Январь",
  "Февраль",
  "Март",
  "Апрель",
  "Май",
  "Июнь",
  "Июль",
  "Август",
  "Сентябрь",
  "Октябрь",
  "Ноябрь",
  "Декабрь",
];
