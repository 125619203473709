import axios from "axios";
import { AuthApi, BASE_URL } from "./api";
import { AuthorizationStatus } from "./const";

const REQUEST_TIMEOUT = 60000;
export const secureToken = process.env.REACT_APP_X_TOKEN;

export const API = axios.create({
  timeout: REQUEST_TIMEOUT,
  baseURL: BASE_URL,
});

API.interceptors.request.use((config) => {
  if (!config?.headers) {
    throw new Error(
      `Expected 'config' and 'config.headers' not to be undefined`,
    );
  }
  const act =
    localStorage?.getItem("session") &&
    JSON.parse(localStorage?.getItem("session"));
  if (act && act?.a) config.headers.Authorization = `Bearer ${act.a}`;
  config.headers["X-Access-Token"] = secureToken;

  return config;
});

API.interceptors.response.use(
  (config) => {
    return config;
  },
  async (err) => {
    const originalReq = err.config;
    const status = err.response ? err.response.status : null;
    if (status === 401 && err.config && !err.config._isRetry) {
      const rt =
        localStorage?.getItem("session") &&
        JSON.parse(localStorage?.getItem("session"));
      originalReq._isRetry = true;
      try {
        const response = await axios.patch(AuthApi.REFRESH, null, {
          headers: {
            Authorization: `Bearer ${rt?.r}`,
            "X-Access-Token": secureToken,
          },
        });
        if (response.status >= 200 && response.status < 300) {
          const authData = JSON.stringify({
            a: response?.data?.access_token,
            r: response?.data?.refresh_token,
          });
          err.config.headers["Authorization"] = response.data.access_token;
          localStorage.setItem("session", authData);

          return API.request(originalReq);
        }

        return API.request(originalReq);
      } catch (e) {
        console.log(e);
        localStorage.removeItem("session");
        localStorage.setItem("isAuth", AuthorizationStatus.NO_AUTH);

        return e;
      }
    }
    return err;
  },
);
