import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

import { Pages } from "shared/libs/path";

import DashboardPage from "pages/dashboard";
import Login from "pages/login";
import ProtectedRoute from "./with-auth";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setIsLogged } from "./store/authReducer";
import { AuthorizationStatus } from "shared/libs/const";
import PostsPage from "pages/posts";

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path={Pages.LOGIN} element={<Login />} />
      <Route
        path={Pages.BASE}
        element={
          <ProtectedRoute>
            <DashboardPage />
          </ProtectedRoute>
        }
      />

      <Route
        path={Pages.POSTS}
        element={
          <ProtectedRoute>
            <PostsPage />
          </ProtectedRoute>
        }
      />
    </>,
  ),
);

const Router = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const rt =
      localStorage?.getItem("session") &&
      //@ts-ignore
      JSON.parse(localStorage?.getItem("session"));

    if (rt && rt?.a) dispatch(setIsLogged(AuthorizationStatus.AUTH));
  }, []);
  return <RouterProvider router={router} />;
};

export default Router;
