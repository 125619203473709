import { Dispatch, SetStateAction } from "react";
import PostList from "./ui/postList/PostList";

const Posts = ({
  id,
  setId,
}: {
  id: string;
  setId: Dispatch<SetStateAction<string>>;
}) => {
  return (
    <div className=" w-[70%] p-[1rem]">
      <PostList setId={setId} id={id} />
    </div>
  );
};
export default Posts;
