export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const Endpoints = {
  LOGIN: "/auth/login",
  LOGOUT: "/auth/logout",
  REFRESH: "/auth/refresh",
};

export const AuthApi = {
  LOGIN: `${BASE_URL}${Endpoints.LOGIN}`,
  LOGOUT: `${BASE_URL}${Endpoints.LOGOUT}`,
  REFRESH: `${BASE_URL}${Endpoints.REFRESH}`,
};

export const StatisticsApi = {
  GET_PLAN: `${BASE_URL}/statistics/`,
  GET_TRANSACTIONS_BY_DAY: `${BASE_URL}/statistics/transaction_count_per_day/`,
  GET_MARGIN_MONTH: `${BASE_URL}/statistics/monthly_margin/`,
  GET_MARGIN_DAY: `${BASE_URL}/statistics/daily_margin/`,
};

export const TransactionsApi = {
  GET_SWAP_HISTORY_TODAY: `${BASE_URL}/transactions/swap/history/present_day/`,
  GET_SWAP_HISTORY_BY_DATE: `${BASE_URL}/transactions/swap/history/specific_day/`,
  GET_TRANSACTION: `${BASE_URL}/transactions/search/specific_transaction/`,
};
export const PostApi = {
  GET_CATEGORIES: `${BASE_URL}/notifications/categories/`,
  GET_lANGUAGES: `${BASE_URL}/notifications/languages/`,
  GET_POSTS: `${BASE_URL}/notifications/`,
  GET_POST: `${BASE_URL}/notifications/specific/`,
  GET_CURRENT_POST: `${BASE_URL}/notifications/specific/`,
  PUT_POST: `${BASE_URL}/notifications/`,
  PUT_APPROVE: `${BASE_URL}/notifications/approve/`,
  PUT_DISAPPROVE: `${BASE_URL}/notifications/disapprove/`,
  DELETE_POST: `${BASE_URL}/notifications/`,
  POST_CREATE_POST: `${BASE_URL}/notifications/`,
};
