import PostForm from "comopnents/form";
import { useState } from "react";
import { PostApi } from "shared/libs/api";
import { API } from "shared/libs/conf-axios";
import { isSuccessStatusCode, millisToSeconds } from "shared/libs/utils";
import { CreatePostType } from "shared/types";

function CreatePost() {
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const onFinish = (values: CreatePostType) => {
    const date = values.publication_time;

    values.publication_time = +millisToSeconds(date).toFixed(0);
    const createPost = async () => {
      setIsSubmitLoading(true);
      try {
        const res = await API.post(PostApi.POST_CREATE_POST, values);
        if (isSuccessStatusCode(res.status)) {
          window.location.reload();
        }
      } catch (error) {
        console.log(error);
      }
    };
    createPost();
  };
  return (
    <PostForm
      submitTitle="Создать пост"
      onFinish={onFinish}
      isSubmitLoading={isSubmitLoading}
      title="Создание поста"
    />
  );
}

export default CreatePost;
